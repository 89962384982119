@import '../../../sass/variables';
@import '../../../sass/mixins';

.standings-container {
  padding-top: 16px;

  .standings-header {
    margin: 0;
    padding: 0;
    font-family: 'Teko-Semibold';
    font-size: 1.5rem;
    font-weight: normal;
    text-transform: capitalize;
    text-align: center;
  }

  .standings-content {
    padding: 0 1rem 1rem 1rem;
  }

  .main-navigation {
    z-index: 100;
    background-color: #050505;
    font-weight: 700;
  }

  table {
    [data-shortname]:before {
      content: attr(data-shortname);
    }
    font-size: 16px;
    margin-top: 1rem;

    thead {
      tr {
        th {
          background-color: transparent;
          font-size: 18px;
          color: #afafaf;
          padding: 14px;
          text-align: center;
          border-top: none;
          &:nth-child(1) {
            width: 5%;
            text-align: center;
          }
          &:nth-child(2) {
            width: 40%;
            text-align: left;
          }
          &:nth-child(3) {
            width: 5%;
          }
          &:nth-child(4) {
            width: 5%;
          }
          &:nth-child(5) {
            width: 5%;
          }
          &:nth-child(6) {
            width: 5%;
          }
          &:nth-child(7) {
            width: 5%;
          }
          &:nth-child(8) {
            width: 5%;
          }
          &:nth-child(9) {
            width: 5%;
          }
          &:nth-child(10) {
            width: 5%;
          }
          &.form-container-header {
            width: 20%;
            text-align: left;
          }
        }
      }
    }

    tbody {
      tr {
        &.highlight {
          background-color: #efefef;
          color: #666;
          font-weight: 700;
          border-left: solid 2px #cd131e;
        }

        td {
          border-top: none;
          border-bottom: solid 1px #dfdfdf;
          font-size: 14px;
          padding: 14px;
          vertical-align: middle;
          text-align: center;
          &:nth-child(1) {
            text-align: center;
          }
          &:nth-child(2) {
            text-align: left;
          }
          &.form-container {
            font-weight: 500;
          }

          .flex {
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }

        img {
          max-height: 40px;
        }
      }
    }

    .won {
      margin-right: 4px;
      display: inline-block;
      padding: 0 6px;
      width: 24px;
      height: 24px;
      @include border-radius(3px);
      background-color: green;
      color: $white;
      &:before {
        margin-left: -3px;
        content: 'GY';
        font-size: 0.875rem;
        line-height: 1.8;
      }
    }

    .draw {
      margin-right: 4px;
      display: inline-block;
      padding: 0 6px;
      width: 24px;
      height: 24px;
      @include border-radius(3px);
      background-color: darkgoldenrod;
      color: $white;
      font-weight: 500;
      &:before {
        padding-left: 2px;
        content: 'D';
        font-size: 0.875rem;
        line-height: 1.8;
      }
    }

    .lost {
      margin-right: 4px;
      display: inline-block;
      padding: 0 6px;
      width: 24px;
      height: 24px;
      @include border-radius(3px);
      background-color: $red;
      color: $white;
      font-weight: 500;
      &:before {
        padding-left: 2px;
        content: 'V';
        font-size: 0.875rem;
        line-height: 1.8;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .standings-container {
    padding-top: 48px;

    .standings-header {
      font-size: 2rem;
      text-align: left;
    }

    .xxl {
      display: table-cell;
    }

    .tab-container {
      text-align: center;
      ul {
        display: block;
        margin: 0 0 2rem 0;
        padding-bottom: 0;
        padding-left: 0;
        li {
          position: relative;
          float: left;
          width: auto;
          padding: 0 1rem;
          &:first-of-type {
            padding-left: 0;
          }
          a {
            color: #555555;
            text-transform: uppercase;
            padding: 1rem 1.5rem;
            min-width: 160px;
          }
        }
      }
    }

    .standings-content {
      padding: 0;
    }

    .reserves-squad-list {
      display: inline-block;
      max-width: 15%;
    }

    table {
      thead {
        tr {
          th {
            padding: 18px;
          }
        }
      }
      tbody {
        tr {
          td {
            font-size: 16px;
            padding: 18px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .standings-container {
    table {
      [data-name]:before {
        content: attr(data-name);
      }
    }
  }
}
