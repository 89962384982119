@import '../../../sass/variables';
@import '../../../sass/mixins';

.player-list-container {
  .player-list-header {
    margin: 1rem 0 2rem 0;
    padding: 0 1rem;
    font-family: 'Teko-Semibold';
    font-size: 2.5rem;
    font-weight: normal;
    position: relative;
    text-transform: uppercase;
    text-align: center;

    &::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -20px;
      width: 40px;
      height: 3px;
      background-color: #a72b2b;
    }
  }

  .roster {
    margin: 1rem 0 1.5rem 0;
    display: inline-block;
    cursor: pointer;
    font-family: 'Teko-Semibold', sans-serif;
    font-size: 1.4rem;
    color: $red;
    text-decoration: underline;

    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}

@media screen and (min-width: 992px) {
  .player-list-container {
    .roster {
      margin: 1.5rem 0 1rem 0;
    }
  }
}
