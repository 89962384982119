.results-type-navigation-container {
  margin: 0 auto 1rem auto;
  width: fit-content;

  ul {
    display: flex;
    list-style: none;
    position: relative;
    margin: 0;
    padding: 0;

    button {
      background-color: transparent;
      color: #aaa;
      border: none;
      font-size: 0.85rem;
      font-weight: 600;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      position: relative;

      &.active {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.18);
        background: white;
        border-radius: 54px;
        color: #444;
      }
    }
  }

  @media only screen and (min-width: 992px) {
    margin: 0 auto;
    ul {
      button {
        font-size: 1rem;
        padding: 1rem 2rem;
      }
    }
  }
}
