.banner-container {
    margin-left: 0;
    margin-right: 0;
    padding: 2.5rem;
    background-color: #222222;
    text-align: center;

    .show-on-mobile {
        @media only screen and (min-width: 769px) {
            display: none;
        }
    }

    .not-show-on-mobile {
        display: none;
        @media only screen and (min-width: 769px) {
            display: block;
        }
    }

    &__red {
        background-color: #a72b2b;
    }

    img {
        max-width: 100%;

        &.desktop {
            display: none;
        }
    }

    .loader {
        margin: auto;
        width: 100%;
        max-width: 400px;
    }
}

@media only screen and (min-width: 992px) {
    .banner-container {
        margin-left: -2rem;
        margin-right: -2rem;

        &__red {
            margin-left: 0;
            margin-right: 0;
        }

        img {
            &.mobile {
                display: none;
            }

            &.desktop {
                display: inline;
            }
        }

        .loader {
            width: 960px;
            max-width: 960px;
            height: 120px;
        }
    }
}
