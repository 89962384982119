@import '../../../sass/variables';
@import '../../../sass/mixins';

.player-card-oneside {
  @include box-shadow(0, 0, 8px, rgba(0, 0, 0, 0.2));
  @include border-radius(4px);
  background-color: #fff;
  padding: 8px;
  .inner {
    @include background-gradient(#afafaf, #fafafa);
    position: relative;
  }
  figure {
    margin: 0;
    img {
      max-width: 100%;
    }
  }

  .player-data {
    padding: 8px 8px 4px 8px;
    background-color: #fff;

    p {
      margin: 0;
      color: #222;
      font-size: 1.5rem;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
