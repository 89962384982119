// Variables
@import '../../variables';

// Mixins
@import '../../mixins';

.error-page {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -323px;
    margin-left: -383px;
    h1 {
        padding: 1rem 0;
        font-size: 5rem;
        text-align: center;
    }

    p {
        font-size: 1.25rem;
        text-align: center;
        line-height: 1.4;

        a {
            color: $red;
            text-decoration: none;
            border-bottom: solid 1px #dfdfdf;
            transition: 250ms;

            &:hover {
                border-bottom: solid 1px #ffffff;
            }
        }
    }

    figure {
        text-align: center;
    }
}
