@import '../../variables';
@import '../../mixins';

.breadcrumb {
  margin-bottom: 0 !important;
  padding: 0 !important;
  background-color: #efefef;

  &__item {
    display: none;

    &:last-of-type {
      a {
        font-weight: 600;
        border-bottom: solid 2px $red;
        color: #555555;
      }

      span {
        display: none;
      }
    }

    &:nth-last-of-type(2) {
      display: block;
      a {
        display: block;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;

        &:hover,
        &:active {
          color: $red;
        }

        &:before {
          color: $red;
          content: '»';
          font-size: 1.2rem;
          padding-right: 1rem;
        }
      }
    }
  }

  a {
    display: none;
    font-weight: 300;
    text-transform: uppercase;
    &:nth-last-of-type(2) {
      display: block;
      padding: 0.8rem 1rem 0.6rem 1rem;
      color: #1d2124;
      border-bottom: solid 2px transparent;
      &:hover,
      &.active {
        border-bottom: solid 2px $red;
        color: #050505;
      }

      &:before {
        color: $red;
        content: '\00ab';
        font-size: 1.2rem;
        padding-right: 1rem;
      }
    }
  }

  .separator {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .breadcrumb {
    margin-bottom: 0;
    padding: 0;
    width: 100%;

    &__item {
      display: inline-block;

      &:nth-last-of-type(2) {
        display: inline-block;
        a {
          display: inline-block;
          padding: 12px 16px 8px 16px;
          &:before {
            display: none;
          }
        }
      }

      &:last-of-type {
        pointer-events: none;
      }

      a {
        display: inline-block;
        font-size: 0.8rem;
        padding: 12px 16px 8px 16px;
        color: #1d2124;
        border-bottom: solid 2px transparent;
        &:hover,
        &.active {
          border-bottom: solid 2px $red;
          color: #050505;
        }
      }
    }

    .separator {
      display: inline;
      padding: 0 0.5rem;
      &:before {
        color: $red;
        content: '\00bb';
        font-size: 1.2rem;
      }
    }
  }
}
