@import '../../../sass/variables';
@import '../../../sass/mixins';

.staff-container {
    .main-navigation {
        background-color: rgb(5,5,5);
    }
  h3 {
      margin: 0;
      padding: 1rem 0;
      font-family: 'Teko-Semibold';
      font-size: 2.5rem;
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
  }

  .staff-card {
      position: relative;
      margin: 1rem;
      @include box-shadow(0, 0, 7px, rgba(0,0,0,.18));
      a {
          &:hover {
              color: $black;
              img {
                  transform: scale(1.05);
              }
          }
      }
      figure {
          @include background-gradient(#808d95, #fdfdfd);
          overflow: hidden;
          img {
              transition: 500ms;
              max-width: 100%;
          }
      }
      .details {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          margin: 0;
          padding: 1rem;
          background-color: rgba(255,255,255,0.75);
          h4 {
              font-size: 1.20rem;
              font-weight: 700;
              text-transform: uppercase;
          }
          p {
              margin-bottom: 0;
              color: $black;
          }
      }
  }
}

@media only screen and (min-width: 992px) {
    .staff-container {
      h3 {
          padding: 2rem 0 1rem 0;
          text-align: left;
      }
      .staff-card {
          margin: 0 0 1.500rem 0;
      }
    }
}
