@import '../../../sass/variables';
@import '../../../sass/mixins';

.image-viewer-container {
  position: relative;
  width: 100%;
  background-color: #1d1d1d;
  color: $white;

  main {
    > .row {
      > div {
        &:first-of-type {
          order: 2;
        }

        &:last-of-type {
          order: 1;
        }
      }
    }
  }

  .image-items-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .image-item {
      padding: 1rem 0;
      width: 100%;

      figure {
        &.show {
          display: block;
        }
        &.hide {
          display: block;
        }
        img {
          width: 100%;
        }
      }
    }

    .pager {
      display: none;
      width: 2.5%;
      text-align: center;
      font-size: 3rem;

      &.previous {
        padding-right: 1rem;
        &.hidden {
          a {
            display: none;
          }
        }
        a {
          img {
            right: 0;
          }
          &:active {
            img {
              right: 10px;
            }
          }
        }
      }

      &.next {
        padding-left: 1rem;
        &.hidden {
          a {
            display: none;
          }
        }
        a {
          img {
            left: 0;
          }
          &:active {
            img {
              left: 10px;
            }
          }
        }
      }

      img {
        position: relative;
        max-width: 100%;
        @include transition(250ms);

        &.rotated {
          transform: rotate(180deg);
        }
      }
    }
  }

  .sidebar {
    height: 100%;
    background: transparent;
    border-left: solid 1px #555555;

    h2 {
      padding: 1rem 0 0.5rem 0;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1.5;
    }

    p {
      font-size: 0.9rem;
      &.date {
        color: #afafaf;
      }
    }

    &__headline {
      border-bottom: solid 1px #444444;
      text-align: center;

      a {
        color: inherit;
        text-decoration: underline;
        text-decoration-color: #999999;
        &:hover {
          color: $red;
          text-decoration: none;
        }
      }
    }

    &__thumbnails {
      display: none;
      height: calc(100% - 230px);
      margin: 20px 0;
      flex-wrap: wrap;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      &::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      &::-webkit-scrollbar-thumb {
        background: #444444;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #666666;
      }
      &::-webkit-scrollbar-thumb:active {
        background: #222222;
      }
      &::-webkit-scrollbar-track {
        background: #222222;
        border: 0px none #ffffff;
        border-radius: 50px;
      }
      &::-webkit-scrollbar-track:hover {
        background: #555555;
      }
      &::-webkit-scrollbar-track:active {
        background: #222222;
      }
      &::-webkit-scrollbar-corner {
        background: transparent;
      }

      figure {
        img {
          border: solid 2px transparent;
        }
        &.active {
          img {
            border: solid 2px $red;
          }
        }

        flex: 50%;
        overflow: hidden;
        padding: 0 0.25rem;
        &.hide {
          display: none;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .close-btn {
    background-color: $red-secondary;
    border-radius: 20px;
    color: $white;
    display: block;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none !important;
    text-transform: uppercase;
    transition: 250ms all;

    &:hover {
      color: $white !important;
      opacity: 0.9;
      text-decoration: none !important;
    }
  }
}

@media screen and (min-width: 992px) {
  .image-viewer-container {
    position: fixed;
    width: 100%;
    height: 100%;

    main {
      height: 100%;
      > .row {
        margin-right: 0;
        margin-left: 0;
        height: 100%;

        > div {
          height: 100%;

          &:first-of-type {
            order: 1;
          }

          &:last-of-type {
            order: 2;
          }
        }
      }
    }

    .image-items-container {
      .image-item {
        padding: 1rem 0;
        width: 95%;

        figure {
          &.show {
            display: block;
          }
          &.hide {
            display: none;
          }
          img {
            width: 100%;
          }
        }
      }

      .pager {
        display: block;
      }
    }

    .sidebar {
      display: block;

      h2 {
        font-size: 0.9rem;
      }

      p {
        font-size: 0.7rem;
      }

      &__headline {
        height: 160px;
        text-align: left;
      }

      &__thumbnails {
        display: flex;
        height: calc(100% - 160px);

        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-around;
        align-content: flex-start;

        figure {
          &.active {
            img {
              border: solid 2px $red;
            }
          }

          flex: 100%;
          padding: 0 0.25rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .image-viewer-container {
    .sidebar {
      h2 {
        font-size: 1rem;
      }

      p {
        font-size: 0.8rem;
      }

      &__thumbnails {
        height: calc(100% - 200px);

        figure {
          flex: 50%;
          padding: 0 0.25rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1400px) {
  .image-viewer-container {
    .sidebar {
      h2 {
        font-size: 1.25rem;
      }

      &__headline {
        height: 170px;
      }

      &__thumbnails {
        height: calc(100% - 210px);

        figure {
          flex: 50%;
          padding: 0 0.25rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1600px) {
  .image-viewer-container {
    .sidebar {
      h2 {
        font-size: 1.5rem;
      }

      p {
        font-size: 0.9rem;
      }

      &__headline {
        height: auto;
      }

      &__thumbnails {
        height: calc(100% - 220px);
      }
    }
  }
}
