@import "../../variables";

.match-centre {
  &__comment {
    background-color: #f1f1f1;
    padding: 1.5rem;
    margin-top: 1rem;
    border-radius: 6px;
    border-top: solid 3px #a72b2b;
    font-size: 1rem;
    line-height: 1.5;
    color: $text-gray;
  }
}

.match-centre__content {
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  h3 {
    font-size: 2.25rem;
    text-align: center;
  }

  h4 {
    margin-bottom: 2rem;
    font-size: 2rem;
    text-align: center;
  }
}

.match-centre__events {
  padding: 1.5rem 1rem;
}

.match-centre__sidebar {
  padding: 2rem;
  height: 100%;
  background-color: #f9f9f9;
}

.match-centre__event {
  margin: 0;
  padding: 2rem 0;
  border-bottom: solid 1px #d7d7d7;
  min-height: 12rem;
  color: $text-gray;

  &.goal {
    .time {
      border-right: solid 2px $red;
    }

    .description {
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        content: "";
        width: 20px;
        height: 20px;
        background: url("https://depo.honvedfc.hu/web/images/coverage/event-goal.png")
          no-repeat;
      }
    }
  }

  &.caution {
    .time {
      border-right: solid 2px #f3e11c;
    }

    .description {
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        content: "";
        width: 18px;
        height: 20px;
        background: url("https://depo.honvedfc.hu/web/images/coverage/event-yellow-card.png")
          no-repeat;
      }
    }
  }

  &.substitution {
    .time {
    }

    .description {
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        content: "";
        width: 18px;
        height: 20px;
        background: url("https://depo.honvedfc.hu/web/images/coverage/event-substitution.png")
          no-repeat;
      }
    }
  }

  .time {
    font-size: 2rem;
    border-right: solid 1px $black;
    text-align: center;
    line-height: 1;
  }

  p {
    font-size: 1rem;
    line-height: 1.4;
    color: $text-gray;
  }

  img {
    max-width: 100%;
  }
}

.match-centre__sidebar-lineups {
  .tab-head-container {
    border-bottom: solid 1px #cd131e;
    display: flex;
    margin-top: 1.5rem;
    align-items: center;
    justify-content: center;

    > a {
      cursor: pointer;
      flex: 50%;
      margin: 0;
      padding: 0.5rem;
      text-align: center;

      &:hover {
        color: inherit;
      }
    }

    .active {
      background-color: $red-secondary;
      color: white;
      &:hover {
        color: white;
      }
    }
  }

  .tab-content {
    display: none;
    &.active {
      display: block;
    }
  }

  table {
    tbody {
      tr {
        td {
          &:nth-child(1) {
            width: 25px;
          }

          &:nth-child(3) {
            width: 40px;
          }
        }
      }
    }
  }
}

.match-centre__sidebar-standings {
  table {
    margin-top: 1.5rem;
    margin-bottom: 1rem;

    thead {
      tr {
        th {
          background-color: #252525;
          color: white;
        }
      }
    }

    tbody {
      tr {
        &.highlight {
          background-color: $red-secondary;
          color: white;
        }
      }
    }
  }

  .btn-outline-info {
    border-color: #666666;
    color: #666666;
    border-radius: 0;
    text-transform: uppercase;
    width: 100%;

    &:hover {
      background-color: inherit;
    }
  }
}

@media only screen and (min-width: 992px) {
  .match-centre__content {
    h3 {
      text-align: left;
    }

    h4 {
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      text-align: left;
    }
  }

  .match-centre__events {
    padding: 2.5rem;
  }

  .match-centre__event {
    .time {
      font-size: 3rem;
      border-right: solid 1px $black;
      text-align: right;
      line-height: 1;
      span {
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }

  .match-centre__sidebar-lineups {
    .tab-head-container {
      > div {
        margin: 1rem 0 0 0;
      }
    }
  }
}
