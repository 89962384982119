@import '../../../sass/variables';
@import '../../../sass/mixins';

.results-header-background {
  background: #1a1a1a url(https://depo.honvedfc.hu/web/images/draft/matches-background-v2.jpg)
    no-repeat left top;
  background-size: auto 100%;
}

.results-type-navigation-container {
  background: #f8f8f8;
  padding: 0 1.25rem;
  border-radius: 79px;
}

.relative {
  position: relative;

  &.spinner-container {
    padding: 3rem 0;
  }
}

.results-item-container {
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 0;
  .item {
    flex-direction: column;
    width: 100%;
  }
}

.results-container {
  .tab-container {
    ul {
      position: relative;
      display: flex;
      gap: 8px;
      list-style: none;
      margin: 0 1rem 0 1rem;
      padding-left: 0;
      height: 100%;

      li {
        height: 100%;
        padding: 0;
        &:last-of-type {
          &:after {
            display: none;
          }
        }
        a {
          color: white;
          position: relative;
          display: block;
          line-height: 1.4;
          padding: 8px;
          text-align: center;
          cursor: pointer;
          z-index: 2;
          &.active {
            font-weight: 600;
            border-bottom: solid 4px #fff;
          }
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  .results-header {
    color: white;
    margin: 0;
    padding: 3.5rem 0 0 1.5rem;
    font-family: 'Teko-Semibold';
    font-size: 2.2rem;
    font-weight: normal;
    letter-spacing: 1px;
    margin-bottom: 0.5rem;
    text-transform: capitalize;
  }

  .results-month {
    margin-bottom: 0;
    padding: 2rem 0 1rem 0;
    font-size: 1.25rem;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  .main-navigation {
    z-index: 100;
    background-color: #050505;
    font-weight: 700;
  }

  .fixtures-container {
    .no-data {
      font-size: 1.5rem;
      font-weight: 600;
      padding-top: 3rem;
      text-align: center;
    }

    .sorting {
      text-align: center;

      .rotated {
        transform: rotate(180deg);
      }

      .btn-icon {
        border: none;
        padding: 0 2px;
      }

      svg {
        width: 32px;
      }
    }
  }

  .result-item {
    display: flex;
    background-color: $white;
    flex-direction: column;
    margin: 0 16px;
    padding: 20px;
    position: relative;
    gap: 20px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.18);

    .free-entry {
      align-items: center;
      display: flex;
      gap: 8px;
      margin-bottom: 0;
    }

    &.home-match {
      .home-squad-name {
        color: $red;
      }
    }

    &.away-match {
      .away-squad-name {
        color: $red;
      }
    }

    .details {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .away-squad {
        width: 120px;
        img {
          max-height: 72px;
        }
      }

      .match-details {
        .kickoff {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 8px;
        }

        .home-squad-name,
        .away-squad-name {
          align-items: center;
          display: flex;
          justify-content: space-between;
          font-size: 1.25rem;
          font-weight: 600;
          gap: 16px;
          line-height: 1;
          margin-bottom: 2px;
          text-transform: uppercase;

          > div {
            align-items: center;
            display: flex;
            gap: 12px;
          }

          img {
            height: 48px;
          }
        }

        .away-squad-name {
          margin-bottom: 14px;
        }

        .venue {
          color: #999;
          font-size: 16px;
          line-height: 1.25;
          margin-bottom: 0;
        }
      }
    }

    .match-league-info {
      display: flex;
      flex-direction: row;
      text-align: left;

      > div {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
      }

      p {
        margin: 0;
        font-weight: 600;
      }

      h3 {
        font-size: 0.9rem;
        font-weight: 600;
        margin: 0 6px;
        line-height: 1.6;

        &::after {
          content: '.';
        }
      }
    }

    .match-actions {
      display: flex;
      flex-direction: column;
      gap: 8px;

      > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .btn {
        border: none;
        background-color: $red;
        border-radius: 25px;
        color: white;
        padding: 10px 32px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
        text-transform: none;
        transition: all 250ms;

        &:hover {
          opacity: 0.85;
        }

        &:focus {
          outline: none;
          box-shadow: none;
        }

        &.btn-outline {
          background-color: transparent;
          border: solid 1px #999;
          color: #050505;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .results-header-background {
    background: #1a1a1a url(https://depo.honvedfc.hu/web/images/draft/matches-background-v2.jpg)
      no-repeat right top;
    background-size: auto 100%;
    padding-bottom: 84px;
  }

  .results-type-navigation-container {
    background: #f8f8f8;
    padding: 1.25rem 1.25rem;
    border-radius: 79px;
    position: absolute;
    top: -75px;
    left: 50%;
    transform: translateX(-50%);
  }

  .results-container {
    .tab-container {
      padding: 0 2rem;
      text-align: center;

      ul {
        margin: 0;
        padding-bottom: 0;
        padding-left: 0;
        li {
          width: auto;
          padding: 0;
          &:first-of-type {
            padding-left: 0;
          }
          a {
            color: #fff;
            text-transform: capitalize;
            padding: 1rem 1.5rem;
          }
        }

        &::after {
          content: '';
          position: absolute;
          background-color: #666;
          bottom: 1px;
          left: 0;
          width: 100%;
          height: 1px;
        }
      }
    }

    .results-header {
      margin: 0;
      padding: 4rem 2rem 0 2rem;
      font-size: 4rem;
      margin-bottom: 0.5rem;
    }

    .results-month {
      padding: 3rem 0 1rem 0;
    }

    .fixtures-container {
      position: relative;

      .sorting {
        position: absolute;
        top: 48px;
        right: 0;
        z-index: 2;

        svg {
          width: 32px;
        }
      }
    }

    .result-item {
      margin: 0;
      padding: 16px;
      .details {
        border-bottom: solid 1px #dfdfdf;
        flex-direction: row;
        gap: 32px;
        padding-bottom: 20px;

        .home-squad,
        .away-squad {
          border-right: solid 1px #dfdfdf;

          img {
            max-height: 96px;
          }
        }

        .match-details {
          .home-squad-name,
          .away-squad-name {
            font-size: 2.2rem;
            margin-bottom: 14px;
          }

          .home-squad-name {
            margin-bottom: 6px;
          }

          .venue {
            font-size: 16px;
          }
        }
      }

      .match-league-info {
        border-left: solid 1px #dfdfdf;
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        padding-left: 1.5rem;
        text-align: center;
        width: 120px;
      }

      .match-actions {
        display: flex;
        flex-direction: row;
        gap: 16px;
        justify-content: space-between;

        > div {
          display: flex;
          flex-direction: row;
          gap: 16px;
          margin-left: auto;
        }
      }

      .match-league-info {
        display: flex;
        flex-direction: column;
        text-align: center;

        > div {
          flex-direction: column;
          align-items: center;
        }

        p {
          font-weight: 400;
        }

        h3 {
          font-family: 'Teko-Semibold';
          font-size: 4rem;
          line-height: 1;
          margin: 0;

          &::after {
            display: none;
          }
        }
      }
    }

    .results-month {
      font-size: 1.2rem;
      text-align: left;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .results-container {
    .tab-container {
      padding: 0;
    }
    .results-header {
      padding: 4rem 0 0 0;
    }

    .result-item {
      padding: 24px;

      .match-details {
        .home-squad-name,
        .away-squad-name {
          font-size: 2rem;
          margin-bottom: 10px;
        }

        .venue {
          font-size: 18px;
        }
      }
    }
  }
}
