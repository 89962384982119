// Variables
@import '../../variables';

// Mixins
@import '../../mixins';

.btn {
    &__default {
        cursor: pointer;
        display: block;
        padding: 8px 0;
        @include border-radius(25px);
        margin-top: 1rem;
        background-color: $red-secondary;
        color: $white;
        border: solid 1px $red-secondary;
        @include transition(250ms);
        text-align: center;

        &:hover, &:active {
            color: $red-secondary;
            background-color: transparent;
            border: solid 1px $red-secondary;
        }

        &--big {
            display: block;
            padding: 12px 0;
            @include border-radius(40px);
            margin-bottom: 1rem;
            background-color: $red-secondary;
            color: $white;
            border: solid 1px $red-secondary;
            @include transition(250ms);
            text-align: center;

            &:hover, &:active {
                color: $red-secondary;
                background-color: transparent;
                border: solid 1px $red-secondary;
            }
        }
    }

    &__default-outline {
        &--big {
            display: inline-block;
            padding: 12px 72px;
            background-color: transparent;
            border: solid 1px $red-secondary;
            color: $red-secondary;
            @include border-radius(40px);
            margin-bottom: 1rem;
            @include transition(250ms);
            text-align: center;

            &:hover, &:active {
                color: $white;
                border: solid 1px $red-secondary;
                background-color: $red-secondary;
            }
        }
    }
}
