@import '../../../sass/variables';
@import '../../../sass/mixins';

.photoalbum-container {
    .album-header {
        margin: 0;
        padding: 1rem 0 1rem 0;
        font-family: 'Teko-Semibold';
        font-size: 2.5rem;
        font-weight: normal;
        text-transform: uppercase;
        text-align: center;
    }

    /*.album-item-container {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }*/

    .album-item {
        margin-bottom: 1.500rem;
        a {
            color: inherit;
            >div {
                overflow: hidden;
            }
            img {
                transition: 500ms;
                max-width: 100%;
            }
            &:hover {
                img {
                    transform: scale(1.05);
                }
            }
        }
        p {
            margin: 0;
            padding: 0;
            &:first-of-type {
                padding: 0.5rem 0 0 0;
            }
        }
    }

    .loader-container {
        margin-bottom: 2rem;
    }
}
