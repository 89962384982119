@import '../../../sass/variables';

.video-loader-container {
}

.video-outer-container {
  background-color: #222222;
  padding: 2.5rem 0;

  > .container {
    > div {
      padding-left: 15px;
      padding-right: 15px;

      &:first-child {
        margin-bottom: 2rem;
      }
    }
  }

  h2 {
    margin-bottom: 0;
    padding: 0 0 1rem 0;
    font-family: 'Teko-Semibold', sans-serif;
    font-size: 2.5rem;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    color: $white;
  }
}

.rec-video-container {
  position: relative;

  h3 {
    z-index: 3;
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-indent: 1rem;
  }

  .shadow {
    background: -moz-linear-gradient(top, rgba(5, 5, 5, 0) 60%, rgba(5, 5, 5, 0.8) 100%);
    background: -webkit-linear-gradient(top, rgba(5, 5, 5, 0) 60%, rgba(5, 5, 5, 0.8) 100%);
    background: linear-gradient(to bottom, rgba(5, 5, 5, 0) 60%, rgba(5, 5, 5, 0.6) 100%);
    bottom: 0;
    box-shadow: none !important;
    height: 250px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 2;
  }

  img {
    width: 100%;
  }

  iframe {
    display: none;

    &.show {
      z-index: 4;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.yt-play-btn {
  z-index: 3;
  position: absolute;
  left: 1rem;
  bottom: 4rem;
  background-color: #c70101;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 250ms;

  &:hover {
    transform: scale(1.2);
  }

  .triangle {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -6px;
    margin-left: -5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 10px;
    border-color: transparent transparent transparent #ffffff;
  }
}

@media only screen and (min-width: 992px) {
  .video-outer-container {
    padding: 2.5rem 0;

    > .container {
      > div {
        float: left;
        width: 50%;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    h2 {
      padding: 0.5rem 0 2rem 0;
      font-size: 2.5rem;
    }
  }

  .rec-video-container {
    position: relative;

    h3 {
      bottom: 2rem;
      font-size: 1.5rem;
      text-indent: 1rem;
    }

    .shadow {
      background: -moz-linear-gradient(top, rgba(5, 5, 5, 0) 25%, rgba(5, 5, 5, 0.9) 100%);
      background: -webkit-linear-gradient(top, rgba(5, 5, 5, 0) 25%, rgba(5, 5, 5, 0.9) 100%);
      background: linear-gradient(to bottom, rgba(5, 5, 5, 0) 25%, rgba(5, 5, 5, 0.9) 100%);
    }
  }

  .yt-play-btn {
    left: 1rem;
    bottom: 5rem;
    width: 75px;
    height: 75px;

    .triangle {
      margin-top: -12px;
      margin-left: -10px;
      border-width: 12.5px 0 12.5px 20px;
    }
  }
}
