
// Body
$body-bg: #ffffff;

// Typography
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$black: #050505;
$white: #ffffff;
$blue: #3490dc;
$text-gray: #333333;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #CD131E;
$red-secondary: #a72b2b;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$text-gray: #212529;
$footer-bg: #181818;
