@import '../../../sass/variables';
@import '../../../sass/mixins';

.player-card-container {
  display: grid;
  grid-gap: 0;
  grid-auto-rows: 1fr;
  grid-template-columns: 100%;
  padding: 16px;
  box-sizing: border-box;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
  }

  @media only screen and (min-width: 992px) {
    padding: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.player-card {
  perspective: 40rem;
  display: flex;
  margin: 0 0 1.5rem 0;
  transition: z-index, transform calc(700ms / 4);
  transition-delay: 700ms, 0s;
  text-decoration: none;
  color: inherit;
  z-index: 0;

  &.player-details {
    &:hover {
      transform: scale(1);
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:hover {
    transform: scale(1.1);
  }

  h2 {
    margin-bottom: 1.25rem;
    border-bottom: solid 3px $red;
    padding-bottom: 1.75rem;
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.2;
    > span {
      display: block;
      &:last-of-type {
        font-size: 1.5rem;
      }
    }
  }

  h3 {
    margin: 0;
    font-family: 'Teko-Semibold', sans-serif;
    font-size: 6rem;
    line-height: 0.9;
    color: $red;
  }

  .player-card-inner {
    display: flex;
    transform-style: preserve-3d;
    transition: 700ms transform;
    @include box-shadow(0, 0, 7px, rgba(0, 0, 0, 0.18));
  }

  &:hover {
    transition-delay: 0s;
    z-index: 1;
  }

  &:hover .player-card-inner {
    //transform: rotateY(180deg);
  }

  .player-card__front,
  .player-card__back {
    margin: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    min-width: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .player-card__back {
    transform: rotateY(180deg) translate(100%, 0);
  }

  figure {
    img {
      max-width: 100%;
    }
  }

  table {
    tbody {
      tr {
        &:first-child {
          td {
            border-top: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .player-card {
    margin: 0;
  }
}
