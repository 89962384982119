@import '../../../sass/variables';
@import '../../../sass/mixins';

.photoalbum-container {
    .main-navigation {
        background-color: rgb(5,5,5);
    }

    .album-header {
        margin: 0;
        padding: 1rem 0 1rem 0;
        font-family: 'Teko-Semibold';
        font-size: 2.5rem;
        font-weight: normal;
        text-transform: uppercase;
        text-align: center;
    }

    .album-lead {
        margin-bottom: 0;
        padding-bottom: 1.500rem;
    }

    .photo-item-container {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        padding: 0 1rem;
    }

    .photo-item {
        margin-bottom: 1rem;
        img {
            max-width: 100%;
        }
    }
}

@media only screen and (min-width: 992px) {
    .photoalbum-container {
        .photo-item-container {
            -webkit-column-count: 3;
            -moz-column-count: 3;
            column-count: 3;
            padding: 0;
        }
    }
}
