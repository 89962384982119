@import '../../variables';
@import '../../mixins';

.cookie-consent {
  &__container {
    z-index: 9999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    &.hidden {
      display: none;
    }
  }

  &__header {
    padding-bottom: 16px;
    margin-bottom: 24px;
    border-bottom: solid 1px #e0e0e0;
    display: flex;
    align-items: center;

    .icon.icon-cookie-consent {
      margin-right: 24px;
      max-width: 40px;
    }

    h3 {
      font-size: 30px !important;
      text-align: left !important;
    }

    svg {
      height: 42px;
    }
  }

  &__links {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 16px;

    .btn-text {
      font-weight: 400;
      font-size: 12px;
      font-family: Roboto, sans-serif;
      padding: 0;
      text-decoration: underline;
      color: #212529;
      background-color: transparent;
      box-shadow: none;
      border-right: solid 1px #212529;
      border-radius: 0;
      padding-right: 1rem;
      line-height: 1;

      &:hover {
        text-decoration: none;
      }
    }

    a {
      font-family: Roboto, sans-serif;
      font-size: 12px;
      line-height: 1;
      margin-left: 1rem;
      text-decoration: underline;

      &:hover,
      &:active {
        color: inherit;
        text-decoration: none;
      }
    }
  }

  &__levels {
    display: flex;
    align-items: center;
    margin: 12px 0 24px 0;

    .react-switch {
      margin-left: 16px;
    }
  }

  &__alert,
  &__details,
  &__disclaimer {
    position: absolute;
    padding: 2rem;
    width: 100%;
    bottom: 0;
    background-color: $white;
    text-align: center;
    border-radius: 0;
    @include box-shadow(0, 0, 16px, rgba(0, 0, 0, 0.25));

    .overflow-container {
      overflow-y: auto;
    }

    .icon {
      margin-bottom: 1.5rem;
    }

    .btn-outline {
      background-color: transparent;
      border: solid 2px #CD131E;
      color: #CD131E;
      font-weight: 600;
      box-shadow: none;
    }

    button {
      background-color: $red;
      color: $white;
      padding: 12px 32px;
      border: none;
      border-radius: 26px;
      cursor: pointer;
      transition: 250ms;
      @include box-shadow(0, 4px, 8px, rgba(255, 0, 0, 0.2));
      font-family: Roboto, sans-serif;
      font-weight: 400;
      width: auto !important;

      &:hover {
        opacity: 0.9;
      }
    }

    h3 {
      margin: 0;
      padding: 0;
      font-family: 'Teko-Semibold';
      font-size: 2rem;
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
      line-height: 1;
    }

    p {
      margin: 1rem 0 1.5rem 0;
    }

    .action {
      margin-bottom: 0;
    }

    a {
      font-family: Roboto, sans-serif;
      text-decoration: underline !important;

      &:hover,
      &:active {
        color: inherit;
        text-decoration: none !important;
      }
    }
  }

  &__alert {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  &__disclaimer {
    max-height: 100%;
    height: 100%;
    padding: 1rem 0.5rem 1rem 1rem;

    .overflow-container {
      height: calc(100% - 74px);
      overflow-y: auto;
      padding-right: 20px;

      &::-webkit-scrollbar {
        width: 3px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }

    .btn-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: white;
      padding: 12px 0;
      @include box-shadow(0, 0, 20px, rgba(0,0,0, 0.1));
    }
  }

  &__details {
    max-height: 100%;
    overflow-y: auto;

    form {
      text-align: left;

      h4 {
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
      }

      p {
        margin: 0;
      }

      .consent-accept-container {
        display: flex;
        justify-content: space-between;
        border-top: solid 1px #e0e0e0;
        padding-top: 16px;

        .btn-outline {
          background-color: transparent;
          border: solid 2px #CD131E;
          color: #CD131E;
          font-weight: 600;
          box-shadow: none;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .cookie-consent {
    &__details {
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;

      .icon {
        &.icon-cookie-consent {
          margin: 0 24px 0 0;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .cookie-consent {
    &__alert,
    &__details,
    &__disclaimer {
      width: 50%;
      max-width: 600px;
      top: 50%;
      left: 50%;
      bottom: auto;
      transform: translate(-50%, -50%);
      background-color: $white;
      text-align: center;
      border-radius: 24px;
      @include box-shadow(0, 0, 16px, rgba(0, 0, 0, 0.25));
    }

    &__disclaimer {
      max-height: 80%;
      height: 100%;
      padding: 2rem 1rem 2rem 2rem;

      .overflow-container {
        height: calc(100% - 54px);
        overflow-y: auto;
        padding-right: 20px;
      }

      .btn-container {
        @include box-shadow(0,0,0,#000);

        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        padding: 18px 0;
      }
    }
  }
}
