@import '../variables';
@import '../mixins';

#featured-news {
  background-color: $red-secondary;
  position: relative;

  .featured-news-loader {
    height: 0;
  }

  h2 {
    position: absolute;
    display: block;
    bottom: 38px;
    left: 0;
    z-index: 3;
    margin: 0 20px;
    padding-top: 20px;
    font-size: 1.75rem;
    color: white;
    text-transform: uppercase;
    font-family: 'Teko-Semibold', sans-serif;
    font-weight: normal;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20%;
      height: 5px;
      background-color: $red;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 20%;
      width: 20%;
      height: 5px;
      background-color: $black;
    }
  }

  p {
    position: absolute;
    display: block;
    margin: 0 20px;
    z-index: 3;
    bottom: 10px;
    left: 0;
    font-size: 1rem;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
  }

  img {
    margin-left: 0;
    max-width: 100%;
  }

  .shadow {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    //@include background-gradient()
    background: -moz-linear-gradient(top, rgba(5, 5, 5, 0) 0%, rgba(5, 5, 5, 0.6) 100%);
    background: -webkit-linear-gradient(top, rgba(5, 5, 5, 0) 0%, rgba(5, 5, 5, 0.6) 100%);
    background: linear-gradient(to bottom, rgba(5, 5, 5, 0) 0%, rgba(5, 5, 5, 0.6) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00050505', endColorstr='#050505',GradientType=0 );
    box-shadow: none !important;
  }

  .loader {
    img {
      margin-left: -50%;
      max-width: 250%;
      width: auto;
    }
  }

  .header-image {
    height: 250px;
    background-size: cover;
    display: none;
  }

  figure#wallpaper {
    img {
      &.desktop-image {
        display: none;
      }

      &.mobile-image {
        display: block;
      }
    }
  }

  .article-image-container {
    display: flex;
    flex-direction: column-reverse;

    .article {
      &__category {
        display: none;
      }
    }

    h2 {
      bottom: 0;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      font-size: 2rem;
      color: white;
      padding: 24px 0 16px 0;
      position: absolute;
      text-shadow: 0 0 5px #0f0f0f;
      text-transform: none;

      &::after,
      &::before {
        display: none;
      }
    }

    figure {
      margin-bottom: 0;
    }

    .shadow {
      display: none;
    }
  }
}

@media only screen and (min-width: 380px) {
  #featured-news {
    h2 {
      font-size: 1.75rem;
    }

    p {
      font-size: 1rem;
    }

    .article-image-container {
      h2 {
        padding: 24px 16px 16px 16px;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  #featured-news {
    h2 {
      font-size: 3rem;
    }

    p {
      bottom: 15px;
    }

    figure#wallpaper {
      img {
        &.desktop-image {
          display: block;
        }

        &.mobile-image {
          display: none;
        }
      }
    }

    .article-image-container {
      display: block;

      .article {
        &__category {
          display: block;
        }
      }

      h2 {
        bottom: 60px;
        font-family: 'Teko-Semibold', sans-serif;
        font-weight: normal;
        font-size: 3rem;
        padding-top: 20px;
        position: absolute;
        text-transform: uppercase;

        &::after,
        &::before {
          display: block;
        }
      }
    }

    .shadow {
      display: block;
      height: 200px;
      background: -moz-linear-gradient(top, rgba(5, 5, 5, 0) 0%, rgba(5, 5, 5, 0.8) 100%);
      background: -webkit-linear-gradient(top, rgba(5, 5, 5, 0) 0%, rgba(5, 5, 5, 0.8) 100%);
      background: linear-gradient(to bottom, rgba(5, 5, 5, 0) 0%, rgba(5, 5, 5, 0.8) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00050505', endColorstr='#050505',GradientType=0 );
    }
  }
}

@media only screen and (min-width: 992px) {
  #featured-news {
    position: relative;

    .featured-news-loader {
      height: 131px;
    }

    .article-image-container {
      display: block;

      .article {
        &__category {
          display: block;
        }
      }

      h2 {
        bottom: 100px;
        font-family: 'Teko-Semibold', sans-serif;
        font-weight: normal;
        font-size: 3.5rem;
        color: white;
        padding-top: 20px;
        position: absolute;
        text-transform: uppercase;

        &::after,
        &::before {
          display: block;
        }
      }

      .shadow {
        display: block;
      }
    }

    h2 {
      bottom: 100px;
      left: 0;
      margin: 0 30px;
      padding-top: 20px;
      font-size: 3rem;
      color: white;
      text-transform: uppercase;
      font-family: 'Teko-Semibold', sans-serif;
      font-weight: normal;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 20%;
        height: 5px;
        background-color: #cd131e;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 20%;
        width: 20%;
        height: 5px;
        background-color: #050505;
      }
    }

    p {
      margin: 0 30px;
    }

    .loader {
      img {
        margin-left: 0;
        max-width: 100% !important;
        width: 100% !important;
      }
    }

    .header-image {
      display: block;
      height: 131px;
      overflow: hidden;
    }
  }
}

@media only screen and (min-width: 1200px) {
  #featured-news {
    .featured-news-loader {
      height: 133px;
    }

    .header-image {
      height: 133px;
    }
  }
}

@media only screen and (min-width: 1440px) {
  #featured-news {
    position: relative;

    .featured-news-loader {
      height: 142px;
    }

    h2 {
      font-size: 3.5rem;
    }

    .header-image {
      height: 142px;
    }
  }
}

@media only screen and (min-width: 1650px) {
  #featured-news {
    position: relative;

    h2 {
      font-size: 3.5rem;
    }

    .header-image {
      height: 142px;
    }
  }
}

@media only screen and (min-width: 1900px) {
  #featured-news {
    .header-image {
      height: 142px;
    }
  }
}
