// Variables
@import '../../variables';

// Mixins
@import '../../mixins';

.global-search-container {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0,0,0,0.3);

    &.hidden {
        display: none;
    }

    > div {
        margin: auto;
        padding: 1rem;
        max-width: 800px;
        background-color: $white;
        align-items: center;
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.25);

        input {
            padding: 8px;
            border: solid 1px #dfdfdf;
            width: 668px;
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }

        button {
            width: 100px;
            padding: 9px 0;
            background-color: $red;
            border: none;
            color: $white;
            cursor: pointer;

            &.disabled {
                pointer-events:none;
                opacity: 0.9;
            }
        }
    }
}
