@import '../../../sass/variables';
@import '../../../sass/mixins';

.footer {
  background-color: $white;
  padding-bottom: 74px;

  .headline {
    position: relative;
    margin: 2rem auto;
    padding: 0 1rem;
    font-family: 'Teko-Semibold', sans-serif;
    font-size: 2rem;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    color: inherit;
    line-height: 1.1;

    &:after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -20px;
      width: 40px;
      height: 3px;
      background-color: #a72b2b;
    }
  }

  &__sponsors {
    background-color: $white;
  }

  &__disclaimer {
    position: relative;
    background-color: #2a2a2a;
  }

  &__crest {
    position: absolute;
    top: -50px;
    left: 50%;
    margin-left: -50px;
    margin-bottom: 0;

    img {
      max-width: 100px;
      opacity: 0.9;
    }
  }

  &__social {
    padding: 0.5rem 0 1.5rem 0 !important;
    a {
      display: inline-block;
      margin-right: 8px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #3f3f3f;

      &:hover {
        background-color: #a72b2b;
      }

      img {
        margin-top: 9px;
        max-width: 22px;
        max-height: 22px;
      }
    }
  }

  &__mobile-app {
    padding-bottom: 1.5rem !important;
    a {
      &:first-of-type {
        margin-right: 16px;
      }

      img {
        max-width: 50%;
        height: 40px;
      }
    }
  }

  .partners-category-1 {
    padding: 0 1rem 1rem 1rem;

    a {
      display: inline-block;
      width: 50%;
      text-align: center;

      img {
        padding: 1rem;
        max-width: 100%;
        height: auto;
      }
    }

    p {
      margin: 0;
      padding: 0;

      &:first-of-type {
        margin-top: 2.5rem;
      }
    }
  }

  .partners-category-2,
  .partners-category-3 {
    padding: 1rem;

    a {
      display: inline-block;
      width: 50%;

      img {
        padding: 1rem;
        max-width: 100%;
        height: auto;
      }
    }
  }

  &__disclaimer {
    margin: 5rem 0 0 0;
    padding: 4rem 0 1rem 0;
    background-color: #282828;
    color: $white;

    p {
      font-size: 13px;
      margin: 0 16px;
      text-align: center;

      > .copyright {
        margin-bottom: 0.5rem;
        display: block;
      }

      a {
        &:hover {
          color: $red;
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .footer {
    padding-bottom: 0;

    &__crest {
      top: -75px;
      left: 50%;
      margin-left: -75px;

      img {
        max-width: 150px;
      }
    }

    &__disclaimer {
      margin: 5rem 0 0 0;
      padding: 6rem 0 1rem 0;
    }

    .headline {
      font-size: 2.5rem;
      margin: 2.5rem 0;
      max-width: 100%;
    }

    .partners-category-1 {
      padding: 0 0 2rem 0;
      text-align: center;
      a {
        width: auto;
        img {
          padding: 0 1rem;
          max-width: 100%;
          height: 80px;
        }
      }
    }

    .partners-category-2,
    .partners-category-3 {
      padding-bottom: 0;
      a {
        padding-bottom: 2rem;
        width: auto;
        img {
          padding: 0 12px;
          max-width: 100%;
          height: 40px;
        }
      }
    }

    .disclaimer {
      p {
        > .copyright {
          display: inline;
          &:after {
            padding-left: 0.25rem;
            content: '|';
          }
        }
      }
    }
  }
}
