@import '../../../sass/variables';

.bottomNavigation {
  background-color: #fff;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  gap: 14px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  z-index: 3;

  @media screen and (min-width: 900px) {
    display: none;
  }
}

.item {
  align-items: center;
  border-top: solid 2px transparent;
  justify-content: center;
  padding: 6px 0 8px 0;
  width: 25%;
  text-align: center;

  &:active {
    background-color: #dfdfdf;
  }

  svg {
    width: 28px;
    height: 28px;
  }

  p {
    font-size: 14px;
    line-height: 1;
    margin: 6px 0 0 0;
  }
}

.active {
  border-top: solid 2px $red;

  p {
    font-weight: 600;
  }
}
