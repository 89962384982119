@import '../../../sass/variables';
@import '../../../sass/mixins';

.news-list-header {
  margin: 0;
  padding: 1rem 0 2.5rem 0;
  font-family: 'Teko-Semibold';
  font-size: 40px;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
}

.news-block {
  @include box-shadow(0, 0, 7px, rgba(0, 0, 0, 0.18));
  background-color: white;
  margin-bottom: 2rem;
  min-height: 200px;

  > figure {
    position: relative;
    margin: 0;
    overflow: hidden;
    img {
      width: 100%;
      transition: 500ms;
    }

    .line {
      position: absolute;
      bottom: 10px;
      left: 20px;
      width: 20%;
      height: 3px;
      background-color: #cd131e;
    }
  }

  > div {
    position: relative;
    overflow: hidden;
    color: $text-gray;

    &.details {
      padding: 1rem;
    }

    .line {
      position: absolute;
      bottom: 10px;
      left: 20px;
      width: 20%;
      height: 3px;
      background-color: #cd131e;
    }

    h3 {
      position: relative;
      font-family: 'Teko-Semibold', sans-serif;
      font-size: 28px;
      font-weight: normal;
      text-transform: uppercase;
      line-height: 1.1;
    }

    p {
      font-size: 14px;
      line-height: 1.25;
      &.extra-data {
        display: none;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        margin: 0;
      }
    }
  }

  .shadow {
    display: none;
  }

  &:hover {
    > figure {
      img {
        transform: scale(1.05);
      }
    }

    .details {
      h3 {
        &:before {
          -webkit-animation-name: newsLine;
          -webkit-animation-duration: 1s;
          animation-name: newsLine;
          animation-duration: 1s;
        }
      }
    }
  }
}

.news-block-small {
  @include box-shadow(0, 0, 7px, rgba(0, 0, 0, 0.18));
  margin-bottom: 2rem;
  background-color: white;

  > div {
    position: relative;
    overflow: hidden;
    color: $text-gray;

    &.details {
      padding: 1rem;
    }

    img {
      width: 100%;
      transition: 500ms;
    }

    .line {
      position: absolute;
      bottom: 10px;
      left: 20px;
      width: 20%;
      height: 3px;
      background-color: #cd131e;
    }

    h3 {
      position: relative;
      font-family: 'Teko-Semibold', sans-serif;
      font-size: 24px;
      font-weight: normal;
      text-transform: uppercase;
      line-height: 1.1;
    }

    p {
      font-size: 14px;
      line-height: 1.25;
      &.extra-data {
        display: none;
        position: absolute;
        bottom: 1rem;
        left: 1rem;
        margin: 0;
      }
    }
  }

  &:hover {
    > div {
      img {
        transform: scale(1.1);
      }

      .line {
        -webkit-animation-name: newsLine;
        -webkit-animation-duration: 1s;
        animation-name: newsLine;
        animation-duration: 1s;
      }
    }
  }
}

.editorial-loader-container {
  margin-bottom: 2rem;
}

.featured-loader-container {
  margin-bottom: 2rem;
  height: 295px;
}

.reserves-news-header {
  margin: 1rem 0 2rem 0;
  padding: 0 1rem;
  font-family: 'Teko-Semibold', sans-serif;
  font-size: 40px;
  font-weight: normal;
  position: relative;
  text-align: center;
  text-transform: uppercase;

  &::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 3px;
    background-color: #a72b2b;
  }
}

.more {
  margin: 1rem 0 1.5rem 0;
  display: inline-block;
  cursor: pointer;
  font-family: 'Teko-Semibold', sans-serif;
  font-size: 1.4rem;
  color: $red;
  text-decoration: underline;

  &:hover,
  &:active {
    color: $red;
    text-decoration: none;
  }
}

@media only screen and (min-width: 768px) {
  .news-block {
    position: relative;
    display: flex;
    overflow: hidden;
    @include box-shadow(0, 0, 7px, rgba(0, 0, 0, 0.18));
    margin-bottom: 2rem;
    background-color: white;
    > figure {
      margin: 0;
      img {
        width: 100%;
        transition: 500ms;
        &:hover {
          transform: scale(1.05);
        }
      }

      .line {
        display: none;
      }
    }

    > div {
      &.details {
        z-index: 3;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0 1rem;
        color: $white;

        h3 {
          padding-top: 0.5rem;
          position: relative;
          font-family: 'Teko-Semibold', sans-serif;
          font-size: 32px;
          font-weight: normal;
          text-transform: uppercase;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 20%;
            height: 3px;
            background-color: #cd131e;
          }
        }

        p {
          font-size: 12px;
          line-height: 1.25;
          &.extra-data {
            display: none;
            position: absolute;
            bottom: 1rem;
            left: 1rem;
            margin: 0;
          }
        }
      }
    }

    .shadow {
      display: block;
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 120px;
      background: -moz-linear-gradient(top, rgba(5, 5, 5, 0) 0%, rgba(5, 5, 5, 0.8) 90%);
      background: -webkit-linear-gradient(top, rgba(5, 5, 5, 0) 0%, rgba(5, 5, 5, 0.8) 90%);
      background: linear-gradient(to bottom, rgba(5, 5, 5, 0) 0%, rgba(5, 5, 5, 0.8) 90%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00050505', endColorstr='#050505',GradientType=0 );
    }
  }
}

@media only screen and (min-width: 992px) {
  .news-block {
    min-height: 205px;
    > div {
      &.details {
        h3 {
          font-size: 28px;
          text-shadow: 0px 2px 5px #555;
        }

        p {
          font-size: 14px;
          line-height: 1.4;
          &.extra-data {
            display: none;
          }
        }
      }
    }
  }

  .news-block-small {
    transition: 250ms;
    &:hover {
      transform: scale(110%);
    }

    > div {
      &.details {
        min-height: 170px;
        overflow-y: hidden;
      }

      h3 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
        line-height: 1.4;
        margin-bottom: 0;
      }
    }
  }

  .more {
    margin: 0 0 1rem 0;
  }
}

@media only screen and (min-width: 1280px) {
  .news-block-small {
    > div {
      &.details {
        min-height: 124px;
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .news-block {
    min-height: 314px;

    > div {
      &.details {
        h3 {
          font-size: 32px;
        }
      }
    }
  }

  .news-block-small {
    > div {
      &.details {
        min-height: 125px;
      }
      h3 {
        font-size: 24px;
      }
    }
  }
}
