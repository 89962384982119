.article-gallery {
  background-color: #fff;
  border: solid 1px #dfdfdf;
  padding: 1.25rem;
  margin-bottom: 2rem;
  border-radius: 6px;

  .headline {
    h2 {
      font-family: Teko-SemiBold, sans-serif;
      font-size: 2rem;
      text-transform: uppercase;
    }

    p {
      font-size: 1rem;
    }
  }

  .thumbnails figure {
    position: relative;

    .last-thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        color: white;
        font-size: 2rem;
        font-weight: 600;
        text-align: center;
        margin: 0;
        padding: 0;
      }
    }
  }

  /*.thumbnails {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 16px;

    figure {
      cursor: pointer;
      margin: 0;
      transition: transform 0.2s;
      width: calc(33% - 16px);
      max-width: 33%;

      &:hover {
        transform: scale(1.1);
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 6px;
      }
    }
  }*/

  /*.thumbnails {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(12, 1fr);
    gap: 12px;
    width: 100%;
    aspect-ratio: 2 / 1;
  }

  .thumbnails figure {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 0;
  }

  .thumbnails figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .thumbnails figure:nth-child(1) {
    grid-column: span 4;
    grid-row: span 2;
  }

  .thumbnails figure:nth-child(2) {
    grid-column: span 3;
    grid-row: span 1;
  }

  .thumbnails figure:nth-child(3) {
    grid-column: span 2;
    grid-row: span 1;
  }

  .thumbnails figure:nth-child(4) {
    grid-column: span 3;
    grid-row: span 3;
  }

  .thumbnails figure:nth-child(5) {
    grid-column: span 5;
    grid-row: span 1;
  }

  .thumbnails figure:nth-child(6) {
    grid-column: span 3;
    grid-row: span 1;
  }

  .thumbnails figure:nth-child(7) {
    grid-column: span 3;
    grid-row: span 1;
  }

  .thumbnails figure:nth-child(8) {
    grid-column: span 3;
    grid-row: span 1;
  }*/
}

@media screen and (min-width: 768px) {
  .article-gallery {
    padding: 2rem;

    .thumbnails {
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: repeat(6, 1fr);
      gap: 6px;
    }

    .thumbnails figure:nth-child(1),
    .thumbnails figure:nth-child(2) {
      grid-column: span 3;
    }

    .thumbnails figure:nth-child(3),
    .thumbnails figure:nth-child(4),
    .thumbnails figure:nth-child(5) {
      grid-column: span 2;
    }

    .thumbnails figure {
      margin: 0;
      position: relative;

      a {
        transition: all 250ms;

        &:hover {
          opacity: 0.9;
        }
      }

      .last-thumbnail {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: white;
          font-size: 2rem;
          font-weight: 600;
          text-align: center;
          margin: 0;
          padding: 0;
        }
      }
    }

    .thumbnails figure img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 0;
    }
  }
}

/* GRID alapú megoldás */

/*.thumbnails {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-auto-rows: 150px;
    gap: 10px;
  }

  .thumbnails figure {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    grid-row: span var(--row-span, 1);
  }

  .thumbnails img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .thumbnails figure:nth-child(2n) {
    --row-span: 2;
  }
  .thumbnails figure:nth-child(3n) {
    --row-span: 1;
  }
  .thumbnails figure:nth-child(5n) {
    --row-span: 3;
  }*/
