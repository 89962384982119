@import '../../../sass/variables';
@import '../../../sass/mixins';

.roster-container {
  figure#wallpaper {
    overflow-x: visible;
  }

  .main-navigation {
    background-color: rgb(5, 5, 5);
  }
  .main-content {
    h3 {
      margin: 0;
      padding: 1rem 0;
      font-family: 'Teko-Semibold';
      font-size: 2.5rem;
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
    }

    .player-card {
      position: relative;
      figure {
        img {
          max-width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .roster-container {
    .main-content {
      h3 {
        padding: 2rem 0 1rem 0;
        text-align: left;
      }
    }
  }
}
