@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&subset=latin-ext');
@import 'variables';
@import 'mixins';
@import '~bootstrap';
@import 'components/common/buttons.scss';
@import 'components/common/animations.scss';
@import 'components/common/icons.scss';
@import 'helpers';

body {
  font-family: 'Roboto', sans-serif;
}

.container {
  max-width: 1920px;
  padding: 0;
}

header {
  position: relative;

  figure#wallpaper {
    margin: 0;
    overflow-x: hidden;
  }

  .main-navigation {
    display: none;
  }
}

.hidden {
  display: none;
}

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

#inner-navigation {
  nav {
    position: relative;

    &.show {
      li {
        display: block;
      }

      a.accordion {
        transform: rotate(180deg);
      }
    }

    &.hidden {
      li {
        display: none;

        &:first-child {
          display: block;
        }
      }

      a.accordion {
        transform: rotate(0deg);
      }
    }

    ul {
      padding-left: 0;

      li {
        float: none;
        a {
          &:hover {
            color: white;
            background-color: #050505;
          }
        }
      }
    }

    a.accordion {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 8px;
      height: 8px;
      display: block;
      cursor: pointer;
      transition: 250ms;
      &:before {
        margin-left: 1px;
        float: left;
        display: inline-block;
        content: '';
        width: 1px;
        height: 8px;
        transform: rotate(-45deg);
        color: white;
        background-color: white;
      }
      &:after {
        float: right;
        display: inline-block;
        content: '';
        width: 1px;
        height: 8px;
        transform: rotate(45deg);
        color: white;
        background-color: white;
      }
    }
  }
}

.xxl {
  display: none;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.main-content {
  padding-top: 2rem;
  background-color: #f8f8f8;

  &__darken {
    padding-top: 2rem;
    background-color: #efefef;
  }
}

.article-container {
  .main-content {
    padding-top: 0;
  }
}

.LazyLoad {
  &.is-visible {
    height: auto !important;
  }
}

[data-shortname]:before {
  content: attr(data-shortname);
}

.checkbox-header {
  padding-bottom: 6px;
}

.no-margin {
  margin: 0 !important;
}

@media only screen and (min-width: 420px) {
  header {
    h1 {
      font-size: 1.5em;
      line-height: 2.5;
    }
  }
}

@media only screen and (min-width: 769px) {
  .article-container {
    .main-content {
      padding-top: 1.5rem;
    }
  }

  header {
    figure#wallpaper {
      img {
        margin-left: 0;
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .article-container,
  .roster-container,
  .fixtures-container,
  .standings-container,
  .results-container,
  .staff-container,
  .photoalbum-container {
    header {
      .main-navigation {
        position: relative;
        top: 0;

        .navigation-content {
          li {
            &.frontpage-link {
              display: block;
              margin-right: 14px;
              &:hover {
                background-color: transparent;
              }
              a {
                padding: 0;
                &:hover {
                  background-color: transparent;
                }
                img {
                  width: 68px;
                  height: 36px;
                }
              }
            }
          }
        }
      }
    }

    .main-content {
      padding-top: 2rem;
    }
  }

  header {
    .main-navigation {
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }

    figure#wallpaper {
      margin: 0;
      img {
        margin-left: 0;
        width: 100%;
      }
    }
  }

  #inner-navigation {
    nav {
      &.hidden {
        li {
          display: block;
        }
      }

      ul {
        padding-left: 40px;
        li {
          float: left;
          display: block;

          a {
            &:hover {
              color: $black;
              background-color: $white;
            }
          }
        }
      }

      a.accordion {
        display: none;
      }
    }
  }

  .main-content {
    padding: 2rem;

    &__darken {
      padding: 2rem;
    }
  }

  .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 1200px) {
  .article-container,
  .roster-container,
  .fixtures-container,
  .standings-container,
  .results-container,
  .staff-container,
  .photoalbum-container {
    header {
      .main-navigation {
        .navigation-content {
          li {
            &.frontpage-link {
              a {
                img {
                  width: 72px;
                  height: 38px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .container {
    max-width: 1420px;
  }

  .article-container,
  .roster-container,
  .fixtures-container,
  .standings-container,
  .results-container,
  .staff-container,
  .photoalbum-container {
    header {
      .main-navigation {
        .navigation-content {
          li {
            &.frontpage-link {
              a {
                img {
                  width: 80px;
                  height: 42px;
                }
              }
            }
          }
        }
      }
    }
  }

  header {
    .main-navigation {
      top: 100px;
      height: 42px;
    }
  }
}

@media screen and (min-width: 1680px) {
  .xxl {
    display: table-cell;
  }

  [data-name]:before {
    content: attr(data-name);
  }
}
