@import '../../variables';

.scoreboard {
    display: block;
    padding-bottom: 0.750rem;
    background-color: $red-secondary;
    color: $white;

    .row {
        margin-left: 0;
        margin-right: 0;
    }

    h2 {
        margin: 0;
        padding: 1rem;
        font-size: 1.250rem;
    }

    .scorers {
        display: none;
    }

    figure {
        margin: auto;
        width: 80px;

        img {
            width: 100%;
        }
    }

    ul {
        list-style: none;
        padding-left: 0;
    }

    .match-centre__paragraph--big-score-card {
        font-size: 3.250rem;
        font-weight: 700;
    }

    .match-centre__paragraph {
        margin: 0;
        padding: 5px 0;
        font-size: 0.8rem;
    }
}

@media only screen and (min-width: 992px) {
    .scoreboard {
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -32.5%;
        padding: 1.250rem;
        width: 65%;

        .row {
            margin-left: -15px;
            margin-right: -15px;
        }

        .scorers {
            display: block;
        }

        figure {
            width: 95px;
        }

        h2 {
            padding: 0.5rem 0 0.750rem 0;
            font-size: 1.5rem;
        }

        .match-centre__paragraph--big-score-card {
            font-size: 4rem;
        }

        .match-centre__paragraph {
            font-size: 1rem;
        }
    }
}
