@import '../../../sass/variables';
@import '../../../sass/mixins';

.register-background {
  padding-top: 0 !important;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  &.login-container,
  &.club-register-container,
  &.register-container {
    max-width: 740px;
    padding: 2rem;
    box-shadow: none;
    background-color: white;

    .h2 {
      margin: 0;
      padding: 1rem 0;
      font-family: 'Teko-Semibold';
      font-size: 2.5rem;
      font-weight: normal;
      text-transform: uppercase;
      text-align: center;
    }

    .social-container {
      text-align: center;
      img {
        display: block;
        margin: 1rem auto;
        max-width: 300px;
      }

      .btn-custom-facebook {
        width: 300px;
        height: 40px;
        border: none;
        background-color: #1877f2;
        color: $white;
        font-size: 16px;
        cursor: pointer;
        @include border-radius(5px);
        &:before {
          float: left;
          display: block;
          content: '';
          width: 24px;
          height: 24px;
          background-image: url('https://static.xx.fbcdn.net/rsrc.php/v3/yN/r/szGrb_tkxMW.png');
          margin-left: 6px;
        }
        &:focus {
          border: none;
          outline: none;
        }
      }

      .g-signin2 {
        margin-top: 1rem;
        box-shadow: none !important;
        > .abcRioButton {
          width: 300px !important;
          margin: auto;
          height: 40px !important;

          > .abcRioButtonContentWrapper {
            background-color: $white;
            @include border-radius(5px);

            > .abcRioButtonIcon {
              padding: 11px !important;
              border-right: solid 2px #efefef;
            }

            > .abcRioButtonContents {
              font-size: 16px !important;
              line-height: 40px !important;
            }
          }
        }
      }
    }

    .separator {
      position: relative;
      margin: 20px 0;
      font-size: 1rem;
      text-align: center;
      p {
        z-index: 3;
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 0 12px;
        background-color: white;
        text-transform: uppercase;
      }
      span {
        display: inline-block;
        width: 100%;
        height: 1px;
        position: absolute;
        top: 12px;
        left: 0;
        background-color: #999999;
      }
    }

    input[type='text'],
    input[type='password'],
    input[type='date'],
    select {
      padding: 8px;
      border-radius: 0;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
      border: solid 1px #cfcfcf;
      height: 41px !important;
      &:focus {
        outline: none;
        @include box-shadow(0, 0, 0);
        border: solid 1px #0a0a0a;
      }
    }

    label {
      span {
        color: $red;
      }

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    button,
    a {
      &.btn-primary {
        display: inline-block;
        margin: auto;
        width: 100%;
        padding: 10px;
        background-color: #cd131e;
        border-radius: 22px;
        border: none;
        @include transition(250ms);

        &:hover,
        &:active {
          background-color: #cd131e !important;
          opacity: 0.9;
          box-shadow: none !important;
        }

        &:focus {
          box-shadow: none;
          border: none;
          background-color: #cd131e;
          opacity: 0.9;
        }

        &:disabled {
          cursor: not-allowed;

          &:hover {
            opacity: 0.65;
          }
        }
      }
    }

    a {
      &.lost-password {
        margin-top: 10px;
      }
    }

    .validation_error {
      color: $red;
      font-size: 12px;
    }

    .login-error-message {
      margin-bottom: 1rem;
      padding: 10px;
      background-color: $red;
      color: $white;
      text-align: center;
    }

    .login-success-message {
      margin-bottom: 1rem;
      padding: 10px;
      background-color: $green;
      color: $white;
      text-align: center;
    }

    .predictive-country-list {
      z-index: 2;
      position: absolute;
      top: 72px;
      left: 5px;
      width: 300px;
      background-color: $white;
      @include box-shadow(0, 0, 5px, rgba(0, 0, 0, 0.15));

      a {
        display: block;
        padding: 8px 10px;
        cursor: pointer;
        font-size: 12px;
        @include transition(250ms);

        &:hover {
          background-color: #dfdfdf;
        }
      }
    }

    .already-member-container {
      margin-top: 1rem;
      a {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .register-background {
    padding-top: 2rem !important;
  }

  .login-container,
  .club-register-container,
  .register-container {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    background-color: rgba(255, 255, 255, 0.97);

    button,
    a {
      &.btn-primary {
        width: auto;
        max-width: 50%;
        padding: 10px 90px;
      }
    }
  }
}

.hidden {
  display: none !important;
}
