@import '../../../sass/variables';
@import '../../../sass/mixins';

.article-container {
    .archive-content {
        padding-bottom: 2rem;

        .archive-header {
            margin: 0;
            padding: 1rem 0;
            font-family: 'Teko-Semibold';
            font-size: 2.5rem;
            font-weight: normal;
            text-transform: uppercase;
            text-align: center;
        }

        .search-results-number {
            text-align: center;
        }

        .archive-article {
            padding: 1.500rem 0;
            border-top: solid 1px #dfdfdf;

            h3 {
                font-size: 1.500rem;
                font-weight: 600;
                text-transform: uppercase;

                a {
                    &:hover, &:active {
                        color: $red;
                    }
                }
            }

            p {
                margin: 0;
                padding: 0 0 1rem 0;
                font-size: 1rem;
                color: #333333;
                font-weight: 300;
                line-height: 1.6;
            }

            img {
                max-width: 100%;
            }
        }

        .archive-sidebar {
            padding: 1.500rem 1rem;

            form {
                margin-bottom: 1.500rem;
                text-align: center;
            }

            label {
                margin-bottom: 0;
                font-size: 0.75rem;
                font-weight: 600;
                text-transform: uppercase;
            }

            input {
                border-radius: 0;
            }

            button {
                width: 100%;
                &.btn-submit {
                    margin-top: 1rem;
                    background-color: $red;
                    font-size: 0.85rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: $white;
                    border-radius: 0;
                    padding: 0.5rem;
                }
            }

            h4 {
                padding-bottom: 0.5rem;
                font-size: 1.250rem;
                font-weight: 600;
                text-transform: uppercase;
                text-align: center;
            }

            .search-item {
                display: inline-block;
                margin: 0 8px 8px 0;
                padding: 3px 8px;
                background-color: #dfdfdf;
                cursor: pointer;
                transition: 250ms;

                &:hover, &:active, &:focus, &.active {
                    background-color: $red-secondary;
                    color: $white;
                }
            }
        }
    }

    button {
        max-width: 300px;
        width: 50%;
        &.btn-submit {
            margin-top: 1rem;
            background-color: $red;
            font-size: 0.85rem;
            font-weight: 700;
            text-transform: uppercase;
            color: $white;
            border-radius: 0;
            padding: 0.5rem;
        }
        &.hidden {
            display: none;
        }
    }

    .more-articles {
        button {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media screen and (min-width: 992px) {
    .article-container {
        .archive-content {
            padding-bottom: 2rem;

            .archive-header {
                padding: 1rem 0 2.5rem 0;
            }

            .search-results-number {
                text-align: left;
            }

            .archive-article {
                p {
                    margin: 0;
                    padding: 0;
                    font-size: 1.2rem;
                }
            }

            .archive-sidebar {
                padding: 0 1rem;

                h4 {
                    text-align: left;
                }

                form {
                    text-align: left;
                }
            }
        }
    }
}
