@import '../../../sass/variables';
@import '../../../sass/mixins';

.playlist-container {
    .video-outer-container {
        background-color: transparent;
    }

    .video-header {
        margin: 0;
        padding: 1rem 0 0 0;
        font-family: 'Teko-Semibold';
        font-size: 2.5rem;
        font-weight: normal;
        text-transform: uppercase;
        text-align: center;
    }

    .rec-video-container {
        margin-bottom: 2rem;
    }
}
