.btn-back {
  border: solid 2px #a72b2b;
  color: #a72b2b;
  border-radius: 25px;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 24px;
  padding: 10px 28px 10px 44px;
  position: relative;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.5;

  &::before,
  &::after {
    background-color: #a72b2b;
    content: '';
    font-size: 1.2rem;
    display: inline-block;
    width: 2px;
    height: 8px;
    position: absolute;
    transform: rotate(45deg);
    left: 20px;
    top: 13px;
    margin-right: 16px;
    transition: all 250ms;
  }

  &::after {
    transform: rotate(-45deg);
    top: 19px;
  }

  &:hover {
    color: #a72b2b;
    &::before,
    &::after {
      left: 24px;
    }
  }
}

.report-header {
  border-radius: 32px;
  position: relative;

  img {
    max-width: 100%;
    border-radius: 32px;
  }

  .venue {
    p {
      margin-bottom: 6px;
    }

    .referees {
      span {
        margin-right: 4px;
        &::after {
          content: ',';
        }

        &:last-child {
          margin-right: 0;
          &::after {
            content: '';
          }
        }
      }
    }
  }

  &__base-data {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 90%);
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
    position: absolute;
    bottom: 0;
    height: 65%;
    width: 100%;
    color: white;
  }

  &__content {
    position: absolute;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
  }

  &__results {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;

    > div {
      display: flex;
      align-items: center;
      font-size: 1.25rem;
      font-weight: 600;
      gap: 12px;
      img {
        height: 72px;
      }

      &.separator {
        font-size: 1.5rem;
        line-height: 1;
        padding: 0 0.5rem;
      }

      .goals {
        font-family: 'Teko-Semibold';
        font-size: 4rem;
        line-height: 1;
      }
    }
  }
}

.report-events {
  padding: 2rem;

  h3 {
    font-family: 'Teko-Semibold';
    font-size: 2.5rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    &::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -20px;
      width: 40px;
      height: 3px;
      background-color: #a72b2b;
    }
  }

  &__content {
    display: flex;
    max-width: 1200px;
    margin: auto;
    gap: 12px;

    > div {
      width: 50%;

      &:first-of-type {
        border-right: solid 1px #afafaf;
      }

      .details {
        align-items: center;
        display: flex;
        gap: 10px;
        padding: 8px 0;
        margin-bottom: 8px;

        img {
          width: 20px;
        }
      }
    }
  }
}

.report-lineups {
  padding: 2rem;

  h3 {
    font-family: 'Teko-Semibold';
    font-size: 2.5rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    &::after {
      content: ' ';
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -20px;
      width: 40px;
      height: 3px;
      background-color: #a72b2b;
    }
  }

  h4 {
    font-weight: 600;
    max-width: 1200px;
    margin: 2rem auto 1.75rem auto;
  }

  &__content {
    display: flex;
    max-width: 1200px;
    margin: auto;
  }

  .home-lineup,
  .away-lineup {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    &__item {
      border-bottom: solid 1px #dfdfdf;
      display: flex;
      align-items: center;
      gap: 8px;

      .jersey {
        background-color: #111;
        color: white;
        font-size: 1.25rem;
        font-weight: 600;
        width: 40px;
        height: 40px;
        text-align: center;
        margin-bottom: 8px;
        padding: 8px;
        line-height: 1.2;
      }

      .player {
        font-size: 14px;
        margin-bottom: 8px;
      }
    }

    .squads {
      align-items: center;
      display: flex;
      gap: 8px;
      font-weight: 600;
      margin-bottom: 16px;
      img {
        height: 48px;
      }
    }
  }

  .home-lineup {
    .jersey {
      background-color: #a72b2b;
    }
  }
}
