@import '../../../sass/variables';
@import '../../../sass/mixins';

.article-container {
  figure#wallpaper {
    overflow-x: visible;
  }

  .footer-container {
    background-color: #f8f8f8;
  }
}

.article {
  padding-left: 8px;
  padding-right: 8px;

  .article-header {
    position: relative;
    padding-bottom: 0;
    padding-top: 16px;
    border-bottom: solid 1px #dfdfdf;
    height: auto;

    > span {
      margin-top: 3px;
      margin-bottom: 2px;
      display: block;
      padding: 0 8px;
      font-weight: 300;

      &:first-child {
        padding-left: 0;
        padding-right: 0;
      }

      &.read-time,
      &.publisher {
        margin-bottom: 8px;
        display: inline-block;
      }

      &.read-time {
        border-right: solid 1px #dfdfdf;
        padding-left: 0;
      }

      &.social-share,
      &.email-share {
        margin-top: 0;
        float: right;
        padding: 0 8px;
        position: absolute;
        right: 0;
        top: 18px;
        img {
          max-width: 20px;
        }
      }

      &.email-share {
        padding-right: 0;
      }

      &.social-share {
        border-right: solid 1px #dfdfdf;
        right: 32px;
      }
    }
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 0;
    padding: 2rem 0;
  }

  h4 {
    margin: 0;
    padding: 1rem 0 1.5rem 0;
    font-size: 1.5rem;
    font-weight: 500;
  }

  p {
    margin: 0;
    padding-bottom: 1.75rem;
    font-size: 1.2rem;
    color: #333333;
    font-weight: 300;
    line-height: 1.6;
  }

  iframe,
  img,
  figure {
    max-width: 100%;
  }

  .article-content {
    blockquote {
      margin: 0 0 1.75rem 1rem;
      padding: 0.5rem 1rem;
      border-left: solid 4px $red;
      font-weight: 600;
      text-transform: uppercase;

      p {
        font-weight: inherit;
        text-transform: inherit;
        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    img {
      max-width: 100% !important;
    }

    strong {
      font-weight: 600;
    }

    a {
      font-size: 1.2rem;
      font-weight: 300;
      color: $red !important;
      border-bottom: solid 1px #dfdfdf;
      @include transition(250ms);
      &:hover {
        color: $red !important;
        border-bottom: transparent;
      }
      &[target='_blank'] {
        &:after {
          display: inline-block;
          margin-left: 6px;
          content: '';
          width: 15px;
          height: 12px;
          background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAMCAMAAACKnBfWAAAA51BMVEUAAAANDQ2zs7MICAgEBAQBAQFBQUEpKSkAAAADAwMEBAQMDAwWFhYqKipxcXE3Nzd0dHRTU1MxMTESEhIQEBCYmJgPDw8AAAAPDw8CAgIzMzMYGBgsLCwODg4VFRUtLS1KSko0NDQODg4jIyMaGhpaWlobGxtaWlo3NzcAAAAcHBwkJCQtLS06OjpISEhgYGBERERubm5KSkomJiZoaGguLi5GRkYJCQkiIiIkJCSCgoJGRkZ0dHSCgoJPT08bGxsZGRm6urplZWW/v7+hoaEgICBkZGQAAABHR0cjIyMZGRkgICAPDw8kJMU6AAAASnRSTlMA6Qfp6Ny5Wv7rw7ywrH5zZVxcWFMr/PX08e/o5eXb19LR0M/OzMXDvby7uLaxrqOckI2KiW9qaWhmZWVcWFZQRTYzKBoaEQ8LCwZKX2gAAACNSURBVAjXPc5FEsJAAETRJm7Eg7u7u7sE7n8ehqkkf/cWXdUIY0ZSoca1I98t91yXeDCGKgi9rjIH9JxQwSzR0tSO0vcw9PcXHqlxsB98N1jKyFuUt6a/A44GuDX1IWNiO30D3Ir6ccWT1wCwNoJe1TiArBk9Kv1dLrqOaIuic5p8FsReI83GSKyc1Al/9XMMv1IxjUIAAAAASUVORK5CYII=')
            no-repeat;
          background-size: cover;
          opacity: 0.9;
        }
      }
    }
  }

  ul {
    font-size: 1.2rem;
    color: #333333;
    font-weight: 300;
  }

  a[data-type='pdf'] {
    display: block;
    height: 48px;
    line-height: 2.6;

    &:before {
      margin-right: 1.25rem;
      content: '';
      float: left;
      background: url('https://depo.honvedfc.hu/web/images/draft/pdf_icon.png') no-repeat;
      width: 42px;
      height: 48px;
    }
  }

  table {
    thead {
      tr {
        th {
          background-color: $black;
          color: $white;
        }
      }
    }
  }

  .article-footer {
    padding: 1rem 0;
    border-top: solid 1px #dfdfdf;

    > span {
      padding: 0 1rem;
      font-weight: 300;
      &:first-child {
        padding-left: 0;
        border-right: solid 1px #dfdfdf;
      }
      &:last-child {
        padding-left: 0;
        border-right: solid 1px #dfdfdf;
      }

      &.social-share,
      &.email-share {
        float: right;
        padding: 0 0.75rem;
        img {
          max-width: 20px;
        }
      }
      a {
        font-size: 0.9rem;
        text-transform: uppercase;

        &:hover {
          color: $red;
        }
      }
    }
  }

  .stadium-images {
    img {
      margin-bottom: 1.25rem;
    }
  }

  .partner-headline {
    padding: 1.5rem 0;
    font-weight: 700;
    font-size: 1.75rem;
    text-transform: uppercase;
  }

  .partner-container {
    margin-bottom: 1.5rem;
    background-color: $white;
    border: solid 1px #efefef;
    > div {
      float: left;
    }

    h5 {
      margin: 0;
      padding: 1rem 1rem 0.5rem 1rem;
      font-family: 'Teko-Semibold', sans-serif;
      text-transform: uppercase;
      font-size: 2rem;
    }

    p {
      padding: 0.5rem 1rem;
    }
  }
}

.sidebar {
  background-color: #efefef;
}

.recommended-news-container {
  .recommended-news-header {
    margin: 2rem 0;
    font-family: 'Teko-Semibold', sans-serif;
    font-size: 2.5rem;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
  }
}

.article-container {
  .main-navigation {
    z-index: 100;
    background-color: $black;
    font-weight: 700;
  }
}

@media only screen and (min-width: 380px) {
  .article {
    padding-left: 16px;
    padding-right: 16px;

    .article-header {
      > span {
        &.social-share,
        &.email-share {
          margin-top: 0;
          float: right;
          padding: 0 0.75rem;
          position: absolute;
          right: 0;
          top: 18px;
          img {
            max-width: 20px;
          }
        }

        &.email-share {
          padding-right: 0;
        }

        &.social-share {
          border-right: solid 1px #dfdfdf;
          right: 32px;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .article {
    margin: auto;
    max-width: 60%;
    padding-left: 0;
    padding-right: 0;

    .article-header {
      height: 40px;
      padding-top: 0;

      > span {
        display: inline-block;
        margin-bottom: 0;
        padding: 0 1rem;

        &:first-child {
          padding-right: 0.75rem;
        }

        &.publish-date {
          > span {
            border-right: solid 1px #dfdfdf;
            padding-right: 0.75rem;
            &:first-of-type {
              display: none;
            }
          }
        }

        &.read-time,
        &.publisher {
          display: inline-block;
        }

        &.social-share,
        &.email-share {
          float: right;
          position: static;
        }
      }
    }
  }
}
